<template>
<div class="wechat-videothing-wrapper">
  <div class="videothing">
    <!-- here -->

  </div>
    <a class="wechat-play-image" v-if="isPaused" @click="playVideo" >
      <img src="@/assets/screenshot.jpg" alt="play">
    </a>
</div>
</template>

<script>
import VideoThing from 'videoThing';
import "../../../node_modules/videoThing/dist/videoThing.css";
import {
  bus
} from '../../main';

export default {
  name: 'VideoThing',
  props: {
    settings: Object
  },
  data(){
    return{
      wechat: false,
      isPaused: false,
    }
  },
  created() {

  },
  methods: {
    playVideo(){
      const params = new URLSearchParams(window.location.search);
      const startLocation = +params.get('location') ? +params.get('location') : this._vaData.settings.start_location;

      const startVideoEl = document.getElementById(`location-${startLocation}`).children[0];
      if(startVideoEl.paused){
        startVideoEl.play()
        this.isPaused = false;
      }
    },
    connectToBus(){
      bus.$on('go_back', () => {
        this.videoThing.goBack();
      })
      bus.$on('show_hotspots', () => {
        this.videoThing.showHotspots()
      })
      bus.$on('hide_hotspots', () => {
        this.videoThing.hideHotspots()
      })
      bus.$on('show_navigation', () => {
        this.videoThing.showNavigation()
      })
      bus.$on('hide_navigation', () => {
        this.videoThing.hideNavigation()
      })
      bus.$on('show_menus', () => {
        this.videoThing.showMenus()
      })
      bus.$on('hide_menus', () => {
        this.videoThing.hideMenus()
      })
      bus.$on('go_to_location', (id) => {
        this.videoThing.goToLocation(id)
      })
      bus.$on('playIfWechat', () => {
          if (this.wechat && this.isPaused) this.playVideo()
      })
    },
    onStart(){
      this.connectToBus();


      const elem = document.querySelector('.videothing');
      this.videoThing = new VideoThing(elem, this._vaData.locations, this._vaData.destinations, this.settings);

      this.videoThing.on('location_reached', (id) => {
        bus.$emit('location_reached', id, this.videoThing.history);
      });

      this.videoThing.on('location_left', (id) => {
        bus.$emit('location_left', id, this.videoThing.history);
      });

      this.videoThing.on('custom_button_clicked', (e, item) => {
        bus.$emit('show_content', item.contentId, item.dataSource);
      });

      this.videoThing.on('menu_button_clicked', (e, item) => {
        console.info(`You clicked menu button`, e, item.menu_data);
        // INFO: use e if you want to show dropdownm in button (e.target)
        bus.$emit('show_button_menu', e, item.menu_data);
      });


      // WeChat
      if (this.wechat) { 
        const params = new URLSearchParams(window.location.search);
        const startLocation = +params.get('location') ? +params.get('location') : this._vaData.settings.start_location;
        const startVideo = document.getElementById(`location-${startLocation}`).children[0];
        this.isPaused = startVideo.paused
      }
    }
  },
  mounted() {

    const onStart = this.onStart
    const isWeChat = /micromessenger/i.test(navigator.userAgent);
    this.wechat = isWeChat
    this.$nextTick(() => {
      if (!isWeChat) { 
        onStart()
      }
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onStart, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onStart)
          document.attachEvent('onWeixinJSBridgeReady', onStart)
        }
      } else {
        // WeixinJSBridge already initialized
        onStart()
      }
    });

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
html[lang="zh"] .videothing .button,
html[lang="ko"] .videothing .button {
    white-space: nowrap !important;
    max-width: 500px;
}
.wechat-videothing-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  & > .wechat-play-image{
    position: absolute;
    left: 0;
    top:0;
    z-index: 1;
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
}
.videothing {

    .controls {

        .button {

            padding: 0.5em 1em;
            background-color: $global-emphasis-color;
            border: 1px solid $global-emphasis-color;
            font-family: "zimmer";
            font-size: 14px;
            font-weight: 300;
            max-width: 12em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;

            line-height: 1.15;
            color: #fff;
            border-radius: 3px;
            transition: border-color 0.2s, background-color 0.2s;
            filter: drop-shadow(0px 10px 4px rgba(#000, .2));
            text-transform: none;
            user-select: none;
            &:not(.no-text-transform) {
                text-transform: uppercase;
            }

            &:hover {
                color: $global-emphasis-color;
                background: #fff;
                border-color: $global-emphasis-color;
            }

            // isHighlight button
            &[data-type="hotspot"],
            &[data-type="navigation"]  {
              &.is-highlight{
                position: relative;
                overflow: initial;
                &::before{
                  position: absolute;
                  content: '';
                  height: 40px;
                  width: 40px;
                  top: -20px;
                  right: -25px;
                  background-image: url("./new-button-v1.png");
                  background-repeat: no-repeat;
                  background-size: cover;
                  @media (max-width: $breakpoint-medium) {
                    height: 35px;
                    width: 35px;
                  }
                }
                // &::before{
                //   font-family: "zimmer";
                //   font-size: 9px;
                //   font-weight: 700;
                //   position: absolute;
                //   content: 'NEW';
                //   height: 20px;
                //   width: 30px;
                //   border-radius: 20px;
                //   background-color: rgb(51, 51, 51);
                //   color:white;
                //   top: -10px;
                //   right: -25px;
                //   text-align: center;
                //   padding: 1px 2px;
                //   line-height: 17px;
                // }
              }
            }

            &[data-type="hotspot"] {
            }

            &[data-type="navigation"] {
            }

            &.back-button {
                color: $global-emphasis-color;
                background-color: #fff;
                border: 1px solid $global-emphasis-color;

                &:hover {
                    background-color: $global-emphasis-color;
                    color: #fff;

                }
                i {
                    color: #fff !important;
                }
            }

            // @media (max-width: $breakpoint-medium) {
            //     font-size: 11px;
            // }
            @media (max-width: $breakpoint-medium) {
                height: 35px;
                width: 35px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;

                .button__text {
                    display: none;
                }

                &:after {
                    content: '+';
                    font-size: 20px;
                    display: inline-block;

                }
            }
        }
        .button-square {
            width: 7em;
            height: 7em;
            padding: 0.5em;
            text-align: center;
            .button__text {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

    }

}
</style>
